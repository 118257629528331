import { gql } from "@apollo/client";

const GET_SEARCH = gql`
query GET_SEARCH($search: String) {
  pages(where: {search: $search}, first: 999) {
    nodes {
      id
      uri
      title
    }
  }
  posts(where: {search: $search}, first: 999) {
    nodes {
      id
      uri
      title
    }
  }
  products(where: {search: $search}, first: 999) {
    nodes {
      id
      ... on SimpleProduct {
        uri
        name
      }
    }
  }
}
`;

export default GET_SEARCH;